import * as React from "react";
import Head from "../components/Head";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Service from "../components/Service";
import Instagram from "../components/InstagramEmbeded";
import Recruit from "../components/Recruit";
import Plan from "../components/Plan";
import CateringSliderImage from "../components/CateringSliderImage";

const Catering = () => {
  return (
    <>
      <Head imagePath="https://forustyle-985bb.web.app/ogp-catering.jpg" />
      <Header />
      <CateringSliderImage />
      <Plan />
      <Service />
      <Recruit />
      <Footer />
    </>
  );
};

export default Catering;
