import * as React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ContentArray = {
  forugranola: {
    title: "forugranola",
    description:
      "サクッじゅわっの新食感。オンライン診断で、あなたにピッタリのグラノーラをご提案します。",
    linkText: "診断はこちらから",
    link: "/diagnosis",
  },
  onlineShop: {
    title: "online shop",
    description:
      "オリジナルブレンドのコーヒー、自家製のレモンケーキをはじめ食にまつわるグッズを販売しています。",
    linkText: "オンラインショップはこちら",
    link: "/online_shop",
  },
  forucatering: {
    title: "foru catering",
    description:
      "季節の素材で丁寧に作ったお料理をみなさまの利用シーンに合わせてお届けします。",
    linkText: "ケータリングのプランを見る",
    link: "/catering",
  },
};

const Title = (props) => {
  return (
    <WrapBoxTypo fs="26px" fw="700" ls="5px">
      {props.title}
    </WrapBoxTypo>
  );
};

const ContentTitle = (props) => {
  return (
    <WrapBoxTypo
      fs="20px"
      fw="700"
      lh="30px"
      sx={{ mt: ["30px", "20px"], mb: ["20px", "20px"] }}
    >
      {props.title}
    </WrapBoxTypo>
  );
};

const Description = (props) => {
  return (
    <Box sx={{ mb: ["30px", "0"] }}>
      <WrapBoxTypo fs="14px" color="#747D7A" ls="1px" lh="22px">
        {props.description}
      </WrapBoxTypo>
    </Box>
  );
};

const LinkText = (props) => {
  return (
    <Box
      sx={{
        position: ["static", "absolute"],
        bottom: ["0", "0"],
        mb: ["50px", "0"],
      }}
    >
      <Link to={props.link} style={{ textDecoration: "none" }}>
        <Box>
          <WrapBoxTypo fs="14px" color="#515D59" ls="1px">
            {props.linkText}
          </WrapBoxTypo>
        </Box>
        <Box sx={{ width: "20px", borderBottom: 1, borderColor: "#515D59" }} />
      </Link>
    </Box>
  );
};

const Service = () => {
  return (
    <Box
      sx={{
        mb: ["160px", "200px"],
        mt: ["80px", "200px"],
        pr: "20px",
        pl: "20px",
      }}
    >
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "auto",
          display: ["flex", "block"],
          justifyContent: ["center", "normal"],
        }}
      >
        <Title title="Service" />
      </Box>
      <Box sx={{ maxWidth: "1100px", margin: "auto", padding: "60px 0 0" }}>
        <Box
          sx={{
            display: ["block", "flex"],
            justifyContent: ["", "space-between"],
            alignItems: ["", "stretch"],
          }}
        >
          <Box
            sx={{
              maxWidth: ["unset", "335px"],
              width: "100%",
              height: ["100%", "457px"],
              position: "relative",
            }}
          >
            <Box sx={{ display: ["none", "block"] }}>
              <StaticImage
                alt="img"
                src="../images/service/service-forugranola.jpg"
                placeholder="blurred"
              />
            </Box>
            <Box sx={{ display: ["block", "none"] }}>
              <StaticImage
                alt="img"
                src="../images/service/service-forugranola-sp.jpg"
                placeholder="blurred"
                quality="100"
              />
            </Box>
            <ContentTitle title={ContentArray.forugranola.title} />
            <Description description={ContentArray.forugranola.description} />
            <LinkText
              linkText={ContentArray.forugranola.linkText}
              link={ContentArray.forugranola.link}
            />
          </Box>
          <Box
            sx={{
              maxWidth: ["unset", "335px"],
              width: "100%",
              height: ["100%", "457px"],
              position: "relative",
            }}
          >
            <Box sx={{ display: ["none", "block"] }}>
              <StaticImage
                alt="img"
                src="../images/service/service-onlineShop.jpg"
                placeholder="blurred"
                quality="100"
              />
            </Box>
            <Box sx={{ display: ["block", "none"] }}>
              <StaticImage
                alt="img"
                src="../images/service/service-onlineShop-sp.jpg"
                placeholder="blurred"
                quality="100"
              />
            </Box>
            <ContentTitle title={ContentArray.onlineShop.title} />
            <Description description={ContentArray.onlineShop.description} />
            <LinkText
              linkText={ContentArray.onlineShop.linkText}
              link={ContentArray.onlineShop.link}
            />
          </Box>
          <Box
            sx={{
              maxWidth: ["unset", "335px"],
              width: "100%",
              height: ["100%", "457px"],
              position: "relative",
            }}
          >
            <Box sx={{ display: ["none", "block"] }}>
              <StaticImage
                alt="img"
                src="../images/service/service-forucatering.jpg"
                placeholder="blurred"
                quality="100"
                sx={{ height: "100%" }}
              />
            </Box>
            <Box sx={{ display: ["block", "none"] }}>
              <StaticImage
                alt="img"
                src="../images/service/service-forucatering-sp.jpg"
                placeholder="blurred"
                quality="100"
                sx={{ height: "100%" }}
              />
            </Box>
            <ContentTitle title={ContentArray.forucatering.title} />
            <Description description={ContentArray.forucatering.description} />
            <LinkText
              linkText={ContentArray.forucatering.linkText}
              link={ContentArray.forucatering.link}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Service;
