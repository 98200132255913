import React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Title = (props) => {
  return (
    <WrapBoxTypo fs="26px" fw="700" ls="5px">
      {props.title}
    </WrapBoxTypo>
  );
};

const PlanDescription = (props) => {
  const { isComingSoon = false } = props;
  return (
    <Box
      position="absolute"
      top="0"
      right={props.isRight ? "0" : "auto"}
      left={props.isRight ? "auto" : "0"}
      display="flex"
      alignItems="center"
      height="100%"
    >
      <Box sx={{ maxWidth: "597px", px: "50px", pt: "80px" }} bgcolor="#fff">
        <Box display="flex" alignItems="center">
          <WrapBoxTypo MetropolisMedium fs="53px" ls="5px" color="#DCDFDE">
            {props.num}
          </WrapBoxTypo>
          <WrapBoxTypo fs="25px" fw="500" ml="10px">
            {props.title}
          </WrapBoxTypo>
        </Box>
        <Box whiteSpace="pre-wrap" mb="34px">
          <WrapBoxTypo lh="42px">{props.description}</WrapBoxTypo>
        </Box>

        <Box mb="40px">
          {isComingSoon ? (
            <>
              <StaticImage
                alt="img"
                src="../images/plan/comingSoon.png"
                placeholder="blurred"
                style={{ maxWidth: "495px", maxHeight: "65px", width: "100%" }}
              />
              <WrapBoxTypo fs="12px" mt="16px">
                ※現在多くのご注文をいただいており、既存取引先及び、当店が出店しているWeWork拠点(原宿/渋谷/神谷町)とデロイト(Cafe1968)以外でのケータリングの受注を停止しております。"
              </WrapBoxTypo>
            </>
          ) : (
            <Link to={isComingSoon ? "" : props.link}>
              <StaticImage
                alt="img"
                src="../images/plan/button.png"
                placeholder="blurred"
                style={{ maxWidth: "495px", maxHeight: "65px", width: "100%" }}
              />
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const SpPlanDescription = (props) => {
  const { isComingSoon = false } = props;
  return (
    <Box display="flex" alignItems="center" height="100%">
      <Box sx={{ maxWidth: "597px", px: "24px", pt: "40px" }} bgcolor="#fff">
        <WrapBoxTypo
          MetropolisMedium
          fs="43px"
          ls="5px"
          lh="53px"
          color="#DCDFDE"
        >
          {props.num}
        </WrapBoxTypo>
        <WrapBoxTypo fs="18px" fw="700" mb="20px">
          {props.title}
        </WrapBoxTypo>
        <Box whiteSpace="pre-wrap" mb="34px">
          <WrapBoxTypo fs="14px" lh="30px">
            {props.description}
          </WrapBoxTypo>
        </Box>

        <Box mb="30px">
          {isComingSoon ? (
            <>
              <StaticImage
                alt="img"
                src="../images/plan/comingSoon.png"
                placeholder="blurred"
                style={{ maxWidth: "495px", maxHeight: "65px", width: "100%" }}
              />
              <WrapBoxTypo fs="10px" mt="16px">
                ※現在多くのご注文をいただいており、既存取引先及び、当店が出店しているWeWork拠点(原宿/渋谷/神谷町)とデロイト(Cafe1968)以外でのケータリングの受注を停止しております。"
              </WrapBoxTypo>
            </>
          ) : (
            <Link to={isComingSoon ? "" : props.link}>
              <StaticImage
                alt="img"
                src="../images/plan/button.png"
                placeholder="blurred"
                style={{ maxWidth: "495px", maxHeight: "65px", width: "100%" }}
              />
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const Plan = () => {
  return (
    <Box
      bgcolor="#F3F5F5"
      sx={{ pt: ["80px", "137px"], px: ["20px"], pb: ["80px", "185px"] }}
    >
      <Box
        sx={{
          maxWidth: "1100px",
          margin: "auto",
          display: ["flex"],
          justifyContent: ["center"],
          mb: ["60px"],
        }}
      >
        <Title title="PLAN" />
      </Box>
      <Box sx={{ display: ["none", "block"] }}>
        <Box sx={{ maxWidth: "1100px", margin: "auto" }}>
          <Box width="100%" position="relative" sx={{ mb: ["160px"] }}>
            <Box>
              <StaticImage
                alt="img"
                src="../images/plan/plan-image-1.jpg"
                placeholder="blurred"
                style={{
                  maxWidth: "534px",
                  maxHeight: "620px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <PlanDescription
              num="01"
              link="/order"
              title="お弁当プラン"
              description={`ロケや会議には、十五穀米をベースにしてお肉やお魚、お野菜のお惣菜を丁寧に詰め込んだお弁当がぴったりです。`}
              isRight
            />
          </Box>
          <Box width="100%" position="relative">
            <Box width="100%" display="flex" justifyContent="end">
              <StaticImage
                alt="img"
                src="../images/plan/plan-image-2.jpg"
                placeholder="blurred"
                style={{
                  maxWidth: "534px",
                  maxHeight: "620px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <PlanDescription
              num="02"
              title="ケータリングプラン"
              link="/original_order"
              description={`特別な集まりには、お料理も高低差をつけてセッティング。軽食からしっかりご飯まで、幅広く対応いたします。`}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: ["block", "none"] }}>
        <Box>
          <Box display="flex" justifyContent="center">
            <Box mb="40px">
              <StaticImage
                alt="img"
                src="../images/plan/plan-image-1.jpg"
                placeholder="blurred"
                style={{
                  maxWidth: "220px",
                  maxHeight: "262px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
          <Box>
            <SpPlanDescription
              num="01"
              title="お弁当プラン"
              link="/order"
              description={`ロケや会議には、十五穀米をベースにしてお肉やお魚、お野菜のお惣菜を丁寧に詰め込んだお弁当がぴったりです。`}
              isRight
            />
          </Box>

          <Box display="flex" justifyContent="center" mt="100px">
            <Box mb="40px">
              <StaticImage
                alt="img"
                src="../images/plan/plan-image-2.jpg"
                placeholder="blurred"
                style={{
                  maxWidth: "220px",
                  maxHeight: "262px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
          <Box>
            <SpPlanDescription
              num="02"
              title="ケータリングプラン"
              link="/original_order"
              description={`特別な集まりには、お料理も高低差をつけてセッティング。軽食からしっかりご飯まで、幅広く対応いたします。`}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Plan;
